import React from "react";
import Connection from "./Connection";

const MqttComp = () => {
  return (
    <>
      <Connection />
      
    </>
  );
};

export default MqttComp;
