import React from "react";
import Settings from "../components/mqtt/Settings";

const AdminPage = () => {
  return (
    <div>
      <Settings />
    </div>
  );
};

export default AdminPage;
