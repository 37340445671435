import React from "react";

const Loading = () => {
  return (
	<div className="row">
		 <hr className="hedd"/>
		<section className="section">
      <div className="loading">

		<div className="blocks i1" ></div>
		<div className="blocks i2" ></div>
		<div className="blocks i3" ></div>
		<div className="blocks i4" ></div>
		<div className="blocks i5" ></div>
		<div className="blocks i6" ></div>
		<div className="blocks i7" ></div>
		<div className="blocks i8" ></div>
		<div className="blocks i9" ></div>
		<div className="blocks i10" ></div>
		<div className="blocks i11" ></div>
		<div className="blocks i12" ></div>
		<div className="blocks i13" ></div>
		<div className="blocks i14" ></div>
		<div className="blocks i15" ></div>
		<div className="blocks i16" ></div>
		<div className="blocks i17" ></div>
		<div className="blocks i18" ></div>
		<div className="blocks i19" ></div>
		<div className="blocks i20" ></div>
		<h3 className="loading-text">Loading</h3>
    </div>
    </section>
	</div>
    
  );
};

export default Loading;
