import React from "react";
import SetOut from "./SetOut";


const TemperOuts = () => {
  return (
    <div>
      <SetOut />
    </div>
  );
};

export default TemperOuts;
